<template>
	<div class="connent_title">
		<div class="connent_header">
			<span><a href="/WorkOrder/WrokOrderAccept/List">历史工单</a></span>
		</div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>列表</el-breadcrumb-item>
			<el-breadcrumb-item><a href="/WorkOrder/WrokOrderAccept/List">历史工单</a></el-breadcrumb-item>
			<el-breadcrumb-item>工单</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
	<el-form :inline="true" style="margin-top: 20px">

		<el-form-item label="客户名称" prop="compName">
			<SelectLink url="/BusinessManagement/AllList" v-model="searchData.compName" :selected="searchData.compName" filterable
				field="compName" @change="(val)=>{compName = val.key}"></SelectLink>
		</el-form-item>

		<el-form-item label="报修类型" prop="woType">
			<el-select v-model="searchData.woType" placeholder="请选择报修类型">
				<el-option key="全部" label="全部" value="" />
				<el-option v-for="item in global.dict.RepairType" :key="item" :label="item" :value="item"></el-option>
			</el-select>
		</el-form-item>

		<el-form-item label="报修项目" prop="repairProject">
			<el-select v-model="searchData.repairProject" placeholder="请选择报修项目" style="width: 100%">
				<el-option key="全部" label="全部" value="" />
				<el-option v-for="item in global.dict.RepairProject" :key="item" :label="item" :value="item">
				</el-option>
			</el-select>
		</el-form-item>

		<el-form-item label="工程师" prop="woRepairEngineerName">
			<SelectLink url="/WrokOrderAccept/GetEnglist" v-model="searchData.woRepairEngineerName"
				:selected="searchData.woRepairEngineerId" field="engName"
				@change="(val)=>{woRepairEngineerId = val.key;woRepairEngineerName=val.value}"></SelectLink>
		</el-form-item>

		<el-form-item label="建单时间">
			<el-date-picker v-model="searchData.woCreateTime" type="daterange" range-separator="至"
				start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD">
			</el-date-picker>
		</el-form-item>
		<!--		<el-form-item label="客户电话">-->
		<!--			<el-input v-model="searchData.telphone" placeholder="请输入客户电话"></el-input>-->
		<!--		</el-form-item>-->

		<!--		<el-form-item label="报修类型" prop="woType">-->
		<!--			<el-select v-model="searchData.woType" placeholder="请选择报修类型">-->
		<!--&lt;!&ndash;        <sl-option key="请选择" label="请选择" value=""/>&ndash;&gt;-->
		<!--        <el-option key="全部" label="全部" value=""/>-->
		<!--        <el-option v-for="item in global.dict.RepairType" :key="item" :label="item" :value="item"></el-option>-->
		<!--      </el-select>-->
		<!--		</el-form-item>-->

		<!--    <el-form-item label="所属工单">-->
		<!--      <el-select v-model="searchData.woDisId" placeholder="请选择所属工单">-->
		<!--        <el-option key="全部" label="全部" value=""/>-->
		<!--        <el-option v-for="item in global.dict.AscriptionType" :key="item" :label="item" :value="item"></el-option>-->
		<!--      </el-select>-->
		<!--    </el-form-item>-->

		<el-form-item>
			<el-button-group>
				<el-button type="success" @click="onSearch" v-hasPermission="'/Api/WrokOrderAccept/List'">查询</el-button>
				<el-button type="primary" @click="onExport" v-hasPermission="'/WrokOrderAccept/HistoryList'">导出
				</el-button>
			</el-button-group>
		</el-form-item>
	</el-form>

	<edit_view :params="edit_view" @getTableData="getTableData" @onClose="onClose" />
	<info_view :params="info_view" @getTableData="getTableData" @onClose="onClose" />
	<Assign_view :params="Assign_view" @getTableData="getTableData" @onClose="onClose" />
	<el-table :data="tableData" v-loading="loading" style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
		<el-table-column key="woCode" label="工单编号" prop="woCode"></el-table-column>
		<el-table-column key="compName" label="企业名称" prop="compName"></el-table-column>
		<el-table-column key="maintenanceMethod" label="维修方式" prop="maintenanceMethod"></el-table-column>
		<!--		<el-table-column key="compId" label="企业Id" prop="compId"></el-table-column>-->
		<el-table-column key="telName" label="报修人" prop="telName"></el-table-column>
		<el-table-column key="telphone" label="报修人电话" prop="telphone"></el-table-column>
		<el-table-column key="woType" label="报修类型" prop="woType"></el-table-column>
		<el-table-column key="woDescribe" label="报修描述" prop="woDescribe"></el-table-column><!--    后加的-->
    <el-table-column key="woRepairEngineerName" label="维修工程师" prop="woRepairEngineerName"></el-table-column>
		<el-table-column key="woStatus" label="工单状态" prop="woStatus"></el-table-column><!--    后加的-->
		<el-table-column key="woCreateTime" label="创建时间" prop="woCreateTime"></el-table-column>
		<!--		<el-table-column key="woDispatcherName" label="调度员姓名" prop="woDispatcherName"></el-table-column>-->
		<!--		<el-table-column key="woDisTime" label="调度时间" prop="woDisTime"></el-table-column>-->
		<!--		<el-table-column key="woRepairTime" label="维修时间" prop="woRepairTime"></el-table-column>-->
		<!--		<el-table-column key="woDisId" label="调度员ID" prop="woDisId"></el-table-column>-->
		<!--		<el-table-column key="woRepairEngineerId" label="维修工程师ID" prop="woRepairEngineerId"></el-table-column>-->
		<!--		<el-table-column key="woRepairRecordId" label="维修记录id" prop="woRepairRecordId"></el-table-column>-->
		<!--		<el-table-column key="woStatus" label="工单流转状态" prop="woStatus"></el-table-column>-->

		<!--		<el-table-column key="woQuitStatus" label=" 离职状态" prop="woQuitStatus"></el-table-column>-->
		<!--		<el-table-column key="woCloseTime" label="关单时间" prop="woCloseTime"></el-table-column>-->
		<!--		<el-table-column key="woCloseDisId" label="关单调度员Id" prop="woCloseDisId"></el-table-column>-->
		<!--		<el-table-column key="woCloseDisName" label="关单调度员姓名" prop="woCloseDisName"></el-table-column>-->
		<!--		<el-table-column key="keyWord" label="关键字搜索使用" prop="keyWord"></el-table-column>-->
		<el-table-column label="操作" min-width="150">
			<template #default="scope">
				<el-button-group>

					<el-button v-if="scope.row.woStatus ==='待派单'" size="mini" type="success"
						@click="onAssign(scope.$index, scope.row)" v-hasPermission="'/Api/WrokOrderAccept/Modify'">派单
					</el-button>

					<el-button v-if="scope.row.woStatus ==='待定价'" size="mini" type="success"
						@click="onAssign(scope.$index, scope.row)" v-hasPermission="'/Api/WrokOrderAccept/Modify'">重新派单
					</el-button>

					<el-popconfirm title="确定关单吗？" @confirm="onCloseOder(scope.row)" v-if="scope.row.woStatus ==='待关单'">
						<template #reference>
							<el-button size="mini" type="danger" v-hasPermission="'/Api/WrokOrderAccept/CloseOder'">关单
							</el-button>
						</template>
					</el-popconfirm>

					<el-button size="mini" type="success" @click="onInfo(scope.$index, scope.row)"
						v-hasPermission="'/Api/WrokOrderAccept/List'">查看
					</el-button>

					<el-button v-if="scope.row.woStatus ==='待派单'" size="mini" type="primary"
						@click="onEdit(scope.$index, scope.row)"
						v-hasPermission="'/Api/WrokOrderAccept/ModifyWorkOder'">编辑
					</el-button>

					<el-popconfirm title="确定删除吗？" @confirm="onDelete(scope.row)" v-if="scope.row.woStatus ==='待派单'">
						<template #reference>
							<el-button size="mini" type="danger" v-hasPermission="'/Api/WrokOrderAccept/Remove'">删除
							</el-button>
						</template>
					</el-popconfirm>

				</el-button-group>
			</template>
		</el-table-column>
	</el-table>

	<el-pagination background :pager-count="11" layout="total, prev, pager, next, jumper" :total="pageData.total"
		:page-size="pageData.size" :current-page="pageData.current" @current-change="changePage">
	</el-pagination>

</template>

<script>
	import axios from '@/utils/axios'
	import Assign_view from './Assign'
	import edit_view from './Form'
	import info_view from './Info'
	import {
		ElMessage
	} from 'element-plus'
	import SelectLink from "@/components/SelectLink";


	export default {
		data() {
			return {
				// 全局变量，用于获得配置信息等
				global: window.global,

				loading: false,
				// 编辑窗口组件
				edit_view: {
					show: false, // 是否显示
					type: "", // add or edit
					title: "", //标题
					data: {
						OderCodeno: ''
					}, //数据
					OderCodeno: '123'
				},
				// 查看窗口组件
				info_view: {
					show: false, // 是否显示
					type: "", // add or edit
					title: "", //标题
					data: {} //数据
				},
				// 指派窗口组件
				Assign_view: {
					show: false, // 是否显示
					type: "", // add or edit
					title: "", //标题
					data: {} //数据

				},
				// 分页信息
				pageData: window.global.config.page,
				// 搜索区内容
				searchData: {},
				searchOptions: {},
				// 表格数据
				tableData: []


			}
		},
		components: {
			edit_view, // 编辑窗口组件
			info_view, // 查看窗口组件
			Assign_view, //指派窗口组件
			SelectLink, //下拉选


		},
		created() {
			this.getTableData();


		},

		methods: {
			// 搜索事件
			onSearch() {
				this.getTableData()
			},
			// 导出事件
			onExport() {
				axios.exportExecl(`/WrokOrderAccept/HistoryExport`, `工单受理`, { //url: 接口地址
					params: this.searchData,
					responseType: `arraybuffer` //一定要写
				});
			},
			// 增加事件
			onCreate(index, row) {
				this.edit_view.title = "新增";
				this.edit_view.type = 'add'
				this.edit_view.data = {}
				this.getWorkOrder();
				this.Assign_view.data = row;
				this.edit_view.show = true;

			},
			//指派工程师
			onAssign(index, row) {
				this.Assign_view.title = "指派";
				this.Assign_view.type = 'edit'
				// row.compName=row.id;
				this.Assign_view.data = row;
				this.Assign_view.show = true;
			},
			// // 查看事件
			// onInfo(index, row) {
			//   this.info_view.title = "详情";
			//   this.info_view.data = row
			//   this.info_view.show = true;
			// },
			// 查看事件
			onInfo(index, row) {
				axios.get('/WrokOrderAccept/woRepairInfo?id=' + row.id).then((res) => {
					console.log("打印数据1111", res)
					// this.detailInfo = res.data;
					this.info_view.data = res.data;
				})
				this.info_view.title = "详情";
				this.info_view.show = true;

			},
			// 关单事件
			onCloseOder(row) {
				this.loading = true
				axios.post('/WrokOrderAccept/CloseOder?id=' + row.id, row)
					.then(() => {
						console.log(row)
						ElMessage.success('关单成功')
						this.getTableData()
					}).catch(() => {
						this.getTableData()
					})
			},
			// 删除事件
			onDelete(row) {
				this.loading = true
				axios.post('/WrokOrderAccept/Remove', {
					id: row.id,
					fackId: row.fackId
				}).then(() => {
					ElMessage.success('删除成功')
					this.getTableData()
				}).catch(() => {
					this.getTableData()
				})
			},
			// 编辑事件
			onEdit(index, row) {
				this.edit_view.title = "修改";
				this.edit_view.type = 'edit'
				this.edit_view.data = row;
				this.edit_view.show = true;
			},
			// 返回事件
			onClose() {
				this.edit_view.show = false;
				this.info_view.show = false;
				this.Assign_view.show = false;
			},
			// 获取列表
			getTableData() {
				this.loading = true
				var that = this
				var newsearch = {
					compName: that.searchData.compName,
					woType: that.searchData.woType,
					woRepairEngineerName: that.searchData.woRepairEngineerName,
					repairProject: that.searchData.repairProject
				}
				// console.log(newsearch.woTyepe)
				if (that.searchData.woCreateTime != null) {
					newsearch.woCreateTime = that.searchData.woCreateTime
				}
				// if(this.searchData.woCreateTime!=undefined){
				//   this.searchData.woCreateTime="between."+this.searchData.woCreateTime
				// }
				axios.get('/WrokOrderAccept/HistoryList', {
					params: Object.assign({}, newsearch, this.pageData)
				}).then(res => {
					that.tableData = res.data.list
					that.pageData.total = res.data.total
					that.loading = false
					// console.log("sss", that.tableData)
				}).catch(error => {
					that.loading = false
				})
			},
			//获取工单编号
			getWorkOrder() {
				axios.get('/WrokOrderAccept/BackOderCode', {
					//params: Object.assign({}, this.searchData, this.pageData)
				}).then(res => {
					this.edit_view.data.woCode = res;
				}).catch(error => {})
			},
			// 分页事件
			changePage(page) {
				this.pageData.current = page
				this.getTableData()
			},
		}
	}
</script>
